import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { IndexQuery } from '../../graphql-types'
import { StyledMain, StyledH1, StyledSection, StyledH2, SocialLink, SocialList, SocialListItem } from '../styles'

const IndexPage = ({ data: { contentfulPage } }: PageProps<IndexQuery>) => {
  if (!contentfulPage) return null;

  const { tagline, title } = contentfulPage;

  return <>
    <StyledMain>
      <StyledH1 aria-label={title ?? undefined}>
        nineteen<br />n<span className="i">i</span>ne<span className="t">t</span><span className="y">y</span><span className="hyphen">-</span>never.
      </StyledH1>
    </StyledMain>
    <StyledSection>
      <StyledH2 children={tagline} />
      <SocialList>
        <SocialListItem>
          <SocialLink href='https://instagram.com/ninetynever'>
            instagram
          </SocialLink>
        </SocialListItem>
        <SocialListItem>
          <SocialLink href='https://twitter.com/ninetynever'>
            twitter
          </SocialLink>
        </SocialListItem>
      </SocialList>
    </StyledSection>
  </>
}

export default IndexPage

export const pageQuery = graphql`
  query index {
    contentfulPage(contentful_id: {eq: "7alI7Bkc6CNZQcGUMRJ1JG"}) {
      title
      tagline
    }
  }
`